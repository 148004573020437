import React from 'react';
import '../index.css'; 

const Footer = () => {
  return (
    <div className="footer">
      <a className="footer-link" href="https://www.linkedin.com/">LinkedIn</a>
      <a className="footer-link" href="https://twitter.com/">Twitter</a>
      <p>Made with ❤️ by Muhammad Kunjo</p>
    </div>
  );
};

export default Footer;
