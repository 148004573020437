export const albums = [
  {
    id: 1,
    title: "Africa",
    coverImage: "/assets/images/Africa/000007960014.jpg",
    photos: [
      { id: 1, src: "/assets/images/Africa/000007960015.jpg", alt: "Africa Album" },
      { id: 2, src: "/assets/images/Africa/000007960016.jpg", alt: "Africa Album" },
      { id: 3, src: "/assets/images/Africa/000007960023.jpg", alt: "Africa Album" },
      { id: 4, src: "/assets/images/Africa/000007960030.jpg", alt: "Africa Album" },
      { id: 5, src: "/assets/images/Africa/000007960031.jpg", alt: "Africa Album" },
      { id: 6, src: "/assets/images/Africa/000007960032.jpg", alt: "Africa Album" },
      { id: 7, src: "/assets/images/Africa/000007960034.jpg", alt: "Africa Album" },
      { id: 8, src: "/assets/images/Africa/000007960035.jpg", alt: "Africa Album" },
      { id: 9, src: "/assets/images/Africa/000007960036.jpg", alt: "Africa Album" },
      { id: 10, src: "/assets/images/Africa/000007960037.jpg", alt: "Africa Album" }
    ],
  },
  {
    id: 2,
    title: "Asia",
    coverImage: "/assets/images/Asia/000002310034.jpg",
    photos: [
      { id: 1, src: "/assets/images/Asia/000004810008.jpg", alt: "Asia Album" },
      { id: 2, src: "/assets/images/Asia/000004810001.jpg", alt: "Asia Album" },
      { id: 3, src: "/assets/images/Asia/000004810002.jpg", alt: "Asia Album" },
      { id: 4, src: "/assets/images/Asia/000004810006.jpg", alt: "Asia Album" },
      { id: 5, src: "/assets/images/Asia/000004810010.jpg", alt: "Asia Album" },
      { id: 6, src: "/assets/images/Asia/000004810016.jpg", alt: "Asia Album" },
      { id: 7, src: "/assets/images/Asia/000004810019.jpg", alt: "Asia Album" },
      { id: 8, src: "/assets/images/Asia/000004810020.jpg", alt: "Asia Album" },
      { id: 9, src: "/assets/images/Asia/000004810021.jpg", alt: "Asia Album" },
      { id: 10, src: "/assets/images/Asia/000004810024.jpg", alt: "Asia Album" },
      { id: 11, src: "/assets/images/Asia/000004810026.jpg", alt: "Asia Album" },
      { id: 12, src: "/assets/images/Asia/000004810027.jpg", alt: "Asia Album" },
      { id: 13, src: "/assets/images/Asia/000004810028.jpg", alt: "Asia Album" },
      { id: 14, src: "/assets/images/Asia/000004810029.jpg", alt: "Asia Album" },
      { id: 15, src: "/assets/images/Asia/000002310002.jpg", alt: "Asia Album" },
      { id: 16, src: "/assets/images/Asia/000002310003.jpg", alt: "Asia Album" },
      { id: 17, src: "/assets/images/Asia/000002310006.jpg", alt: "Asia Album" },
      { id: 18, src: "/assets/images/Asia/000002310007.jpg", alt: "Asia Album" },
      { id: 19, src: "/assets/images/Asia/000002310009.jpg", alt: "Asia Album" },
      { id: 20, src: "/assets/images/Asia/000002310011.jpg", alt: "Asia Album" },
      { id: 21, src: "/assets/images/Asia/000002310013.jpg", alt: "Asia Album" },
      { id: 22, src: "/assets/images/Asia/000002310023.jpg", alt: "Asia Album" },
      { id: 23, src: "/assets/images/Asia/000002310024.jpg", alt: "Asia Album" },
      { id: 24, src: "/assets/images/Asia/000002310027.jpg", alt: "Asia Album" },
      { id: 25, src: "/assets/images/Asia/000002310033.jpg", alt: "Asia Album" },
      { id: 26, src: "/assets/images/Asia/000002310034.jpg", alt: "Asia Album" }
    ],
  },
  {
    id: 3,
    title: "South America",
    coverImage: "/assets/images/SouthAmerica/000026100024.jpg",
    photos: [
      { id: 1, src: "/assets/images/SouthAmerica/000026100003.jpg", alt: "South America Album" },
      { id: 2, src: "/assets/images/SouthAmerica/000026100005.jpg", alt: "South America Album" },
      { id: 3, src: "/assets/images/SouthAmerica/000026100008.jpg", alt: "South America Album" },
      { id: 4, src: "/assets/images/SouthAmerica/000026100009.jpg", alt: "South America Album" },
      { id: 5, src: "/assets/images/SouthAmerica/000026100015.jpg", alt: "South America Album" },
      { id: 6, src: "/assets/images/SouthAmerica/000026100017.jpg", alt: "South America Album" },
      { id: 7, src: "/assets/images/SouthAmerica/000026100020.jpg", alt: "South America Album" },
      { id: 8, src: "/assets/images/SouthAmerica/000026100024.jpg", alt: "South America Album" },
      { id: 9, src: "/assets/images/SouthAmerica/000026100025.jpg", alt: "South America Album" },
      { id: 10, src: "/assets/images/SouthAmerica/000026100028.jpg", alt: "South America Album" },
      { id: 11, src: "/assets/images/SouthAmerica/000026100029.jpg", alt: "South America Album" },
      { id: 12, src: "/assets/images/SouthAmerica/000026100037.jpg", alt: "South America Album" },
      { id: 13, src: "/assets/images/SouthAmerica/000077120004.jpg", alt: "South America Album" },
      { id: 14, src: "/assets/images/SouthAmerica/000077120012.jpg", alt: "South America Album" },
      { id: 15, src: "/assets/images/SouthAmerica/000077120014.jpg", alt: "South America Album" },
      { id: 16, src: "/assets/images/SouthAmerica/000077120020.jpg", alt: "South America Album" },
      { id: 17, src: "/assets/images/SouthAmerica/000077120024.jpg", alt: "South America Album" },
      { id: 18, src: "/assets/images/SouthAmerica/000077120026.jpg", alt: "South America Album" },
      { id: 19, src: "/assets/images/SouthAmerica/000077120029.jpg", alt: "South America Album" },
      { id: 20, src: "/assets/images/SouthAmerica/000077120033.jpg", alt: "South America Album" },
      { id: 21, src: "/assets/images/SouthAmerica/000077130006.jpg", alt: "South America Album" },
      { id: 22, src: "/assets/images/SouthAmerica/000077130024.jpg", alt: "South America Album" },
      { id: 23, src: "/assets/images/SouthAmerica/000077130025.jpg", alt: "South America Album" },
      { id: 24, src: "/assets/images/SouthAmerica/000077130036.jpg", alt: "South America Album" },
      { id: 25, src: "/assets/images/SouthAmerica/000077130037.jpg", alt: "South America Album" }
    ],
  },
];
